<template>
    <div>
        <div class="data-form">
            <ValidationObserver ref="importForm">
                <b-row>
                    <b-col cols="12">
                        <ValidationProvider name="club_name" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('club_name')">
                                <b-form-input :validate-error="errors[0]" v-model="defineForm.club_name">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12">
                        <ValidationProvider name="file" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('excel_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file v-model="defineForm.file" :state="errors[0] ? false : null"
                                        :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')" ref="fileInput"
                                        multiple></b-form-file>
                                    <b-button variant="outline-secondary"
                                        @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                    </b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                <div class="alert alert-info mt-2" >
                                    {{ $t('member_search_import_excel') }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
              </b-row>
            </ValidationObserver>
            <processing-button :processing="processing" :label="$t('import')" variant="primary"
                        @click="sendForm" />
        </div>
    </div>
</template>
<script>
import ProcessingButton from "@/components/elements/ProcessingButton";
import SectionService from "@/services/SectionService";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ProcessingButton,
        SemestersSelectbox,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            defineForm: {
                semester_id: null,
                file: null,
            },
            processing: false,
        }
    },
    metaInfo() {
        return {
            title: this.$t("section_operations")
        }
    },
    methods: {
        sendForm() {
            const isValid = this.$refs.importForm.validate();
            
            if (isValid) {
                this.processing = true
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])
                formData.append('semester_id', this.defineForm.semester_id)

                SectionService.importSectionDetailAsExcel(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                        this.$emit('successProcess')
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.importForm)
                    })
                    .finally(() => {
                        this.processing = false
                    })
            }
          
        }
    }
}
</script>
